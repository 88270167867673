import { h } from 'preact';
import { useEffect } from "preact/hooks";
import { Route, Router, getCurrentUrl, route } from 'preact-router';
import { positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic'

import Header from './header';
import { AppContext } from '../context/AppContext';

import Home from '../routes/home';
import Info from '../routes/info';
import Phone from '../routes/phone';
import Otp from '../routes/otp';
import TokenVerify from '../routes/tokenVerify';
import Checkout from '../routes/checkout';
import Success from '../routes/success';
import Payment from '../routes/payment';
import BulutVerify from '../routes/bulutVerify';
import BulutSuccess from '../routes/bulutSuccess';

const alertOptions = {
	timeout: 5000,
	position: positions.TOP_CENTER,
	containerStyle: {
		zIndex: 99999,
		// marginTop: browserIsMobile() ? '50px' : '0px',
		pointerEvents: 'initial',
		borderRadius: '4px',
	},
};

const App = () => {
	useEffect(() => {
		if (!(getCurrentUrl() === '/' || getCurrentUrl() === '/tokenVerify' || getCurrentUrl().includes('/bulutVerify'))) {
			route('/', true);
		}
	}, [])

	return (
		<AlertProvider template={AlertTemplate} {...alertOptions}>
				<AppContext>
					<div id="app">
						<Header />
						<main>
							<Router>
								<Route path={'/'} component={Home} />
								<Route path={'/info'} component={Info} />
								<Route path={'/phone'} component={Phone} />
								<Route path={'/otp'} component={Otp} />
								<Route path={'/tokenVerify'} component={TokenVerify} />
								<Route path={'/bulutVerify'} component={BulutVerify} />
								<Route path={'/checkout'} component={Checkout} />
								<Route path={'/payment'} component={Payment} />
								<Route path={'/success'} component={Success} />
								<Route path={'/bulutSuccess'} component={BulutSuccess} />
							</Router>
						</main>
					</div>
				</AppContext>
		</AlertProvider>


	);
}

export default App;
