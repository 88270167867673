import { h, createContext, ComponentChildren } from "preact";
import { IntlProvider } from 'preact-i18n';
//@ts-ignore
import { RioApi, Globals } from "../types/model";
import { useEffect, useRef, useState } from "preact/hooks";
import { ApiClient } from "../services/apiClient";

import defaultDefinition from '../i18n/tr.json';
import { getCurrentUrl } from "preact-router";

export const ServiceContext = createContext<RioApi | undefined>(undefined);
export const GlobalsContext = createContext<Globals>({ sessionData: null, setSessionData: (o) => undefined, lang: 'tr', changeLanguage: (lang: string) => undefined});

interface Props {
  children: any;
  element?: HTMLElement;
}

export const AppContext = ({ children }: Props) => {
  const [sessionData, setSession] = useState(null);
  const [definition, setDefinition] = useState(defaultDefinition);
  const [lang, setLang] = useState('tr');

  const setSessionData = (param: any) => {
    setSession({ ...sessionData, ...param })
  }

  useEffect(() => {
    const url = getCurrentUrl()
		const hasLangProp = url?.split('lang=');
    if (hasLangProp?.length > 1) {
      const langParam = hasLangProp[1];
      if (langParam === 'tr' || langParam === 'en') {
        const lang = hasLangProp[1];
        changeLanguage(lang);
      }
    } else {
      setDefaultBrowserLanguage();
    }
  }, [])

  const setDefaultBrowserLanguage = () => {
    try {
      if (localStorage.getItem('lang')) {
        const localLang = localStorage.getItem('lang');
        changeLanguage(localLang);
      } else {
        //@ts-ignore
        const userLang = navigator.language || navigator.userLanguage; 
        if (userLang && userLang.includes('en')) {
          changeLanguage('en');
        }
      }
      
    } catch (err) {}
  }

  const changeLanguage = (lang: string) => {
    try {
      setLang(lang);
      localStorage.setItem('lang', lang);
      import('../i18n/' + lang + '.json').then((def) => {
        setDefinition(def?.default);
      })
    } catch (err) {
      console.warn(err);
    }
  }

  const services = useRef(
    new ApiClient({
      baseUrl: "https://7l5b92lnv.api.retter.io/7l5b92lnv",
      debug: false,
    })
  );

  return (
    <ServiceContext.Provider value={services.current}>
			<GlobalsContext.Provider value={{ sessionData, setSessionData, lang, changeLanguage }}>
      <IntlProvider definition={definition}>
        {children}
      </IntlProvider>
			</GlobalsContext.Provider>
    </ServiceContext.Provider>
  );
};
