import { h } from "preact";
import { Text } from 'preact-i18n';

import style from "./style.css";
import { useContext } from "preact/hooks";
import { GlobalsContext } from "../../context/AppContext";

const Header = () => {
  const { lang, changeLanguage } = useContext(GlobalsContext);

  return (
    <header class={style.header}>
      <a href="/" class={style.logo}>
        <h2><Text id={'header.title'}></Text></h2>
      </a>
      <div class={style.localization_container}>
        <div
          class={style.localization_item}
          onClick={() => changeLanguage("tr")}
          style={{
            backgroundColor: lang === "tr" ? "rgba(69, 219, 255, 0.5)" : "",
          }}
        >
          TR
        </div>
        <div
          class={style.localization_item}
          onClick={() => changeLanguage("en")}
          style={{
            backgroundColor: lang === "en" ? "rgba(69, 219, 255, 0.5)" : "",
          }}
        >
          EN
        </div>
      </div>
    </header>
  );
};

export default Header;
